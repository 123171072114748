@import "./variables.scss";

#results {
	display: flex;
	flex-direction: column;

	.payoff {
		font-size: 1.5rem;
	}

	.details {
		font-size: $tiny-font-size;
		line-height: 1.4;
		font-weight: 400;
		text-align: center;
		margin: auto 1em 0 1em;
	}
	.comparison {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		margin-top: 1em;

		.labelContainer {
			position: relative;

			.crosshair {
				background: url(/images/Selector.svg) no-repeat;
				background-size: 100%;
				height: 100%;
				width: calc((100% / 5 * 1.2));
				display: block;
				position: absolute;
				left: -1.2%;
				top: -5.5%;

				/*border-radius: 0.6rem;
				border: 0.65rem solid #2E2A25;*/
				z-index: 1;
				/*box-shadow: 0 2px 3px 0 rgba(0,0,0,0.22);	*/
				overflow: hidden;
				transition: transform 0.3s ease-in-out;

				/*&:before {
					content: "";
					display: block;
					position: absolute;
					transform: rotate(45deg);
					left: calc(50% - 0.5rem);
					bottom: -0.7rem;
					width: 1rem;
					height: 1rem;
					background-color: #000;
				}*/
			}
		}
	}

	.labels {
		margin-top: auto;
		text-align: center;
		.cost {
			position: relative;
			display: inline-block;
			font-size: $small-font-size;
			padding: 0.3em 0.7em;
			span {
				position: relative;
				display: block;
			}
			margin-bottom: 0.9em;
			background: #626b80;
			color: #fff;
			&:after {
				content: "";
				display: block;
				position: absolute;
				width: 1em;
				height: 1em;
				background: #626b80;
				transform-origin: center;
				transform: rotate(45deg);
				left: calc(50% - 0.3em);
				bottom: -0.5em;
			}
		}
		.bar {
			transition: all 0.6s ease-in-out;
			flex-direction: column;
			padding-bottom: 0.5em;
			display: flex;

			.label {
				margin-top: auto;
				font-size: 4rem;
				line-height: 1.2;
				color: #fff;
				text-align: center;
				position: relative;

				.toggle-selector {
					width: 0.5em;
					cursor: pointer;
					height: 0.5em;
					position: absolute;
					background: url(/images/Button/Switch/Acive.svg) no-repeat;
					top: 2.2rem;
					right: calc(50% - 0.9em);
					&:hover {
						background: url(/images/Button/Switch/Hover.svg) no-repeat;
					}
				}
			}

			.under-label {
				color: #fff;
				text-align: center;
			}
			&[data-id="0"] {
				background-color: #01a652;
				box-shadow: 0 8px 12px 0 rgba(6, 148, 45, 0.3);
				border-radius: 2px 2px 0 0;
			}
			&[data-id="1"] {
				background-color: #c0d731;
				box-shadow: 0 8px 12px 0 rgba(101, 153, 52, 0.3);
				border-radius: 2px 2px 0 0;
			}
			&[data-id="2"] {
				background-color: #fef200;
				box-shadow: 0 8px 12px 0 rgba(205, 204, 0, 0.3);
				border-radius: 2px 2px 0 0;
			}
			&[data-id="3"] {
				background-color: #fcb913;
				box-shadow: 0 8px 12px 0 rgba(255, 204, 1, 0.3);
				border-radius: 2px 2px 0 0;
			}
			&[data-id="4"] {
				background-color: #ec1c24;
				box-shadow: 0 8px 12px 0 rgba(255, 102, 0, 0.3);
				border-radius: 2px 2px 0 0;
			}
			&[data-id="5"] {
				background-color: #cc0035;
				box-shadow: 0 8px 12px 0 rgba(204, 0, 53, 0.3);
				border-radius: 2px 2px 0 0;
			}
		}
	}

	.selector {
		padding: 0;
		list-style: none;
		border-radius: 0.6em;
		margin: 0 0 1.5rem 0;

		li {
			background: #fff;
			position: relative;
			display: inline-block;
			width: 20%;
			padding-bottom: calc(20% - 1.1rem);
			cursor: pointer;
			border-bottom: 1rem solid;
		}

		li .label {
			position: absolute;
			height: 100%;
			width: 100%;
			font-weight: 400;
			text-align: center;
			font-size: 3.2rem;
			line-height: 1.8;
			border-top: 1px solid lighten(#626b80, 45%);
			border-right: 1px solid lighten(#626b80, 45%);

			&:first-child {
				border-left: 1px solid #e0e2e7;
			}
		}

		li:hover {
			background: lighten(#626b80, 50%);
		}

		li:first-child {
			border-bottom-left-radius: 0.6rem;
			border-top-left-radius: 0.6rem;

			.label {
				border-top-left-radius: 0.6rem;
			}
		}

		li:last-child {
			border-bottom-right-radius: 0.6rem;
			border-top-right-radius: 0.6rem;

			.label {
				border-top-right-radius: 0.6rem;
			}
		}

		[data-id="0"] {
			border-color: #01a652;
		}
		[data-id="1"] {
			border-color: #c0d731;
		}
		[data-id="2"] {
			border-color: #fef200;
		}
		[data-id="3"] {
			border-color: #fcb913;
		}
		[data-id="4"] {
			border-color: #ec1c24;
		}
		[data-id="5"] {
			border-color: #cc0035;
		}
	}

	.result {
		margin: 0.5rem 0 0 2rem;
		line-height: 1.35;
		.savings {
			float: right;
			width: 8em;
			height: 5em;
			background: url(/images/Savings.svg) no-repeat;
			background-size: 100%;
			.text {
				letter-spacing: 1px;
				font-family: "Lato";
				font-weight: 900;

				text-align: center;
				font-size: 1.6em;
				transform: rotate(-5deg);
				line-height: 2.6;
				color: #fff;
			}
		}
	}
}
