@import './variables.scss';

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

h1 {
  font-family: 'Baloo', sans-serif;
  font-size: 5.5rem;
  text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.9);
  letter-spacing: 1px;
  line-height: 1;
  margin-top: 0.3em;

}

h2 {
  font-family: Lato;
  font-size: 1.7rem;
  text-align: center;
}

h3 {
  font-family: Lato;
  font-size: 1.8rem;
  color: #626B80;
  line-height: 2.4rem;
}
h4 {
  font-family: Lato;
  font-size: 1.4rem;
  color: #626B80;
  line-height: 2.4rem;
}

p {
  font-family: Lato;
  margin-top: 0;
  margin-bottom: 0.75em;
}

a, a:visited, a:active, a:hover {
  color: #00B9E6;
  text-decoration: none;
  &:hover {
    text-decoration: underline;    
  }
}

button {
  background: #00B9E6;
  cursor: pointer;
  box-shadow: 0 8px 12px 0 rgba(0,185,230,0.30);
  border-radius: 2.25rem;
  padding: 0 1.5em 0.2em;
  margin: $margin auto;
  height: 4.5rem;
  line-height: 0.7;
  font-size: 2rem;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  display: block;
  width: 100%;
  max-width: 12em;
  border: none;
  &:hover {
    font-weight: 600;
    background: #00B9E6;
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.10) 0%, rgba(0,0,0,0.10) 100%);
  }

  &.chunky {
    border-radius: 2.75rem;
    height: 5.5rem;    
    font-size: 2.2rem;
    padding: 0.5rem 1.5em 0.7rem;
  }
}

/*Chart label Normal
font-family: Lato-Semibold;
font-size: 40px;
color: #FFFFFF;
letter-spacing: 0;
text-align: center;

Chartlabel normal small
font-family: Lato-Bold;
font-size: 16px;
color: #FFFFFF;
letter-spacing: 0;
text-align: center;

Chart label select
font-family: Lato-Regular;
font-size: 32px;
color: #626B80;
text-align: center;
line-height: 51.8px;
*/

.back-button {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  margin: 0.5rem;
  background: url(/images/Button/Left/Active.svg) no-repeat center;
  background-size: 70%;
  &:hover {
    background-image: url(/images/Button/Left/Hover.svg);
    background-size: contain;
  }
}


header {
  position: relative;
  text-align: center;
  width: 100%;
  padding: 1.5rem; 
  padding-top: 3.75rem;
  color: #fff;
    img.logo {
      display: block;
      margin: auto;
      max-width: 100%;      
    }
    .payoff {
      margin-top: 0.5em;
      line-height: 1.2;
      font-size: $even-smaller-font-size;
    }
}

.main {
  display: flex;
  overflow: hidden;
  margin: calc((100vh - 760px)/2) auto;
  @media screen and (max-height: 760px) {
    margin: 0 auto;
  }

  height: 100%;
  max-height: 760px;
  background-image: linear-gradient(180deg, #00B9E6 0%, #06942D 100%);
  border-radius: 0.25em;

  &[data-show-intro='1'] {  
    #wrapper {
      margin-top: auto;      
    }
  }

  &[data-active='2'] .slideWrapper {
    transform: translate3d(-50%,0,0); 
  }

  &, > #wrapper {
    position: relative;
    width: 100%;
    min-width: 320px;
    max-width: 375px;
    max-height: 158vw;

    @media screen and (min-width: 375px) {
      max-height: 600px;   
      .introHidden .slide {
        min-height: 370px;
      }
    }
  }

  > #wrapper {
    transition: all 0.6s ease-in-out;    
    height: auto;
    #logo-kdbb {
      margin: auto;
      display: block;
      width: 40%;
      flex-grow: 1;
      position: relative;
      img {
        width: 100%;
        vertical-align: middle;
      }
    }
    .content  {
      &:before {
        display: block;
        content: "";
        padding-top: calc(90% * 0.2);
        background: url(/images/IMG/tire@2x.png) no-repeat top center;
        background-size: 90% auto;
      }


      .slideWrapper {
        display: flex;
        width: 200%;
        border-radius: 0.15em;             
        transition: transform 0.5s ease-in-out, height 0.3s ease-in;
        .slide {          
          position: relative;
          width: 50%;
          padding: $padding;          
          background-color: #fff;
          border-radius: 0.15em;          
          #intro {
            transition: opacity 0.3s ease-in;
            padding: 1.5rem 0;
          }
        }    
      }

      overflow: hidden;
      position: relative;
      margin: 1.25rem;
      /*margin-top: calc(16rem + calc(80%*1/5.9));*/
      color: #626B80;
      font-size: 1.75rem;    
    }

    @media screen and (min-width: 375px) {
      background-size: calc(60%}) auto; 

      #logo-kdbb {
        img {
          margin-bottom: 2rem;          
        }
      }

/*
      .content {
        width: calc(80% - #{$margin*2});
        margin: $margin auto;
        margin-bottom: 1em;
      }
*/
    }  
  }
}