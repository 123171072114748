//@import url('https://fonts.googleapis.com/css?family=Titillium+Web:900&display=swap');
@import '~lato-webfont/lato.min.css';
@import '~normalize.css/normalize';

@font-face {
  font-family: 'Baloo';
  font-display: swap;
  src: url('/fonts/Baloo-Regular.woff') format('woff');
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
	line-height: 1.6;
	@media screen and (max-width: 420px) {
		font-size: 62.5%*0.9;
	}
	@media screen and (max-width: 360px) {
		font-size: 62.5%*0.8;
	}

}

#root {
	font-size: 2rem;
	font-weight: bold;
	height: 100%;
	width: 100%;
	color: #fff;
	position: absolute;
  	margin: 0;
  	font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

.hide {display: none;}