@import './variables.scss';

#configurator {
  transition: all 0.3s ease-in, opacity 0.3s ease-in 0.6s;


  .inputs {

    padding: 0.5rem 0;

    /* Hide HTML5 Up and Down arrows. */
    input[type="number"]::-webkit-outer-spin-button, 
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
     
    input[type="number"] {
        -moz-appearance: textfield;
    }

    input {
      background: transparent;
      border: none;
      padding: 0;
      color: #626B80;
      outline: none;
      line-height: 100%;
      height: 100%; 
      overflow: auto; 
    }
    table {
      width: 100%;
      th, td {
        margin: 0;
        padding: 0.75em 0;
        font-size: $smaller-font-size;   
      }
      th {
        text-align: left;
      }
      td {
        text-align: right;
      }
    }
    .wrapper {
      height: 4rem;
      width: 4.75em;
      font-size: 2rem;
      line-height: 2rem;
      vertical-align: middle;
      background: #F4F4F6;
      padding: 0 0.75em 0 1em;
      display: inline-block;
      border-radius: 2rem;
      white-space: nowrap;
      text-align: left;

    }
    .fuel-price {
      input {
        width: 3.5em;
        display: inline-block;
        text-align: left;
      }
    }
    .distance-per-unit {
      td, th {
        border-bottom: 1px solid lighten(#626B80, 45%);     
      }
      input {
        width: 3em;
        display: inline-block;
        text-align: center;        
      }
    }

    .units-per-year {
      input {
        width: 3em;
        display: inline-block;
        text-align: left;
      }
    }

  }
  .fuelSelect {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1.5% 0;
    li {
      flex-grow: 1;
      display: flex;
      position: relative;
      margin: 1.5%;
      background-repeat: no-repeat;
      background-size: 60%;
      background-color: #fff;
      list-style: none;
      cursor: pointer;

      box-shadow: 0 2px 9px 0 rgba(0,0,0,0.15);
      font-size: $small-font-size;
      color: #626B80;
      border-radius: 0.3em;  
      font-weight: 400;
      min-height: 5em;


      &:hover {
        font-weight: bold;
        box-shadow: 0 8px 12px 0 rgba(0,0,0,0.15);
      }  

      .label {
        position: absolute;
        font-size: $small-font-size;
        color: #626B80;        
        bottom: 3.5%;
        left: 0.5em;
      }

      &[data-id="0"] {background-image: url(/images/Icon/Benzine/Normal.svg);}
      &[data-id="0"].selected {background-image: url(/images/Icon/Benzine/Active.svg);}
      &[data-id="1"] {background-image: url(/images/Icon/Diesel/Normal.svg);}
      &[data-id="1"].selected {background-image: url(/images/Icon/Diesel/Active.svg);}
      &[data-id="2"] {background-image: url(/images/Icon/Gas/Normal.svg);}
      &[data-id="2"].selected {background-image: url(/images/Icon/Gas/Active.svg);}
      &[data-id="3"] {background-image: url(/images/Icon/Elektriciteit/Normal.svg);}
      &[data-id="3"].selected {background-image: url(/images/Icon/Elektriciteit/Active.svg);}
      &.selected {
        cursor: default;        
        background-color: #00B9E6;  
        box-shadow: 0 8px 12px 0 rgba(0,185,230,0.45);              
        .label {
          color: #fff;          
        }
      }
      &:after {
        display: block;
        content: "";
        width: 100%;
        padding-bottom: 100%;        
      }
    }
  }
}